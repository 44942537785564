import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Slide } from 'react-slideshow-image';
import Header from '../component/Header'
import H1pic from '../images/h1pic.png'
import VolunteerItem from '../component/VolunteerItem'
import CHRF1 from '../images/CHRF1.png'
import CHRF2 from '../images/CHRF2.png'
import CHRF3 from '../images/CHRF3.png'
import pexels from '../images/pexels.png'
import linkedin from '../images/linkedin.png'
import twitter from '../images/twitter.png'
import archgif from '../images/archcomp.gif'
import 'react-slideshow-image/dist/styles.css';
import { TypeAnimation } from 'react-type-animation';
const Home = () => {
    const [messagerFormInfo, setMessagerFormInfo] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [refAbout, inViewAbout ] = useInView({threshold: 0,});
    const [refDesign , inViewDesign] = useInView({threshold: 0,});
    const [refContact, inViewContact] = useInView({ threshold: 0,});

    const archImages = [
        "/archdesign1.png",
        "/archdesign2.png",
        "/archdesign3.png",
        "/archdesign4.png",
        "/archdesign5.png",
        "/archdesign6.png",
    ];

    const graphicImages = [
        "/graphicdesign1.png",
        "/graphicdesign2.png",
        "/graphicdesign3.png",
        "/graphicdesign4.png",
        "/graphicdesign5.png",
    ];

    const sendMessageToKimia = (e) => { 
        e.preventDefault();
        // validating user input 
      
        if (!name) { 
            setMessagerFormInfo(" Name field is  empty ")
        }

        if (!message) {
            setMessagerFormInfo(" message field is empty")
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setMessagerFormInfo("Email is invalid ")
        }

        if (message && name && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) { 
        setMessagerFormInfo("loading...")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "name": name,
            "email": email,
            "text": message
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://kimiaportfolio-mailserver.vercel.app/", requestOptions)
            .then(response => response.text())
            .then(result => {
                setMessagerFormInfo(result)
            })
                .catch(error => console.log('error', error));
        }
    }

   

    return (
        
        <div className='max-w-[1286px] m-auto'  >
            <Header isAbout={inViewAbout} isDesign={inViewDesign} isContact={inViewContact} />
            <main className='relative' >
                {/* Home page section */}
                <section className='flex flex-col justify-center items-center mt-16 md:flex-row'>
                    <TypeAnimation
                        sequence={['Welcome To My World!', 10000, '']}
                        speed={31}
                        wrapper="h1"
                        repeat={Infinity}
                        className="md:text-[96px] text-[36px]  font-[Satisfy] md:w-1/2"
                    />
                    <img className='w-1/2' src={H1pic} alt="kimia khoram"/>
                </section>

                {/* about section */}
                <section className='md:py-[206px] py-[103px]' id="about" ref={refAbout}>
                    <div style={{
                        backgroundImage: `url("/bgart.png")`
                    }} className='md:w-[553px] md:h-[697px] mx-auto flex items-center  min-h-[400px]' >

                        <h2 className='text-center font-[Satisfy] md:text-[24px] pt-[20px] w-full flex px-12'>
                            Although I pursued an academic rather than creative path in school, I have always been fascinated by the patterns that occur in art, architecture and the natural world, and drawing and painting have heightened my appreciation of them. The art of design and creating something that comes out of your creativity and imagination is beyond crazy yet fascinating. I decided to pursue architecture as a career because it allows you to express your thoughts through spaces, design with fun and precision, and more importantly, it is rewarding to develop a project and watch it turn into a human-scale reality.
                        </h2>
                    </div>
                </section>
          
                {/* graphic design section */} 
                <div ref={refDesign}>
                    <section className='md:py-[206px] py-[103px] ' >
                       
                        <div className='rotate-90 md:w-1/4 m-auto' id="design">
                            <Slide>
                                {graphicImages.map((item, index) => (
                                    <div key={index} className="each-slide-effect -rotate-90">
                                        <div className='h-[348px] bg-contain' style={{ 'backgroundImage': `url(${item})`,"backgroundRepeat": "no-repeat", backgroundPosition: "center" } }></div>
                                    </div>
                                ))}
                            </Slide>
                        </div>
                     
                </section>    
                
                {/* ARCH design section*/}       
                <section className='md:py-[206px] py-[103px]' >
                        <img className=' text-center flex items-center justify-center w-full m-auto' src={archgif} /> 
                        
                    <div className="md:w-[400px] m-auto pt-10">
                            <Slide canSwipe={ false }>
                            {archImages.map((item,index) => (
                                <div key={index} className="each-slide-effect">
                                    <div className='h-[348px]  bg-contain' style={{ 'backgroundImage': `url(${item})`, "backgroundRepeat":"no-repeat" , backgroundPosition:"center"}}></div>
                                </div>
                            ))}
                        </Slide>
                    </div>
                </section>   
                </div>
                {/* CHRF section */}   
                <section className='md:py-[206px] py-[103]' >
                    <div className='flex-col flex md:flex-row gap-12 items-center m-auto w-full justify-center'>
                        <VolunteerItem src={CHRF1} />
                        <VolunteerItem src={CHRF2} />
                        <VolunteerItem src={CHRF3} />
                    </div>
                    <h2 className='md:text-[24px] font-[Satisfy] text-center max-w-[750px] m-auto md:pt-16 pt-8'>Children R Future is a non-profit organization that is established to help orphans and underprivileged children with EB (Epidermolysis Bullosa), PKU (Phenylketonuria), and various types of disabilities.</h2>
                </section>    

                {/* contact section */}    
                <section className='md:pt-[206px] pt-[103px]' id="contact" ref={refContact}>
                    <form className='m-auto md:w-[634px]'>
                        <h2 className='text-center font-[Satisfy] md:text-[32px] md:h-[125px] rounded-[93px] flex  items-center  bg-[#D3756B] px-20 '>If you have any question just send me a massage.</h2> 
                        <input className='block w-1/4 bg-[#FFC3A1] md:text-[24px] rounded-[137px] md:h-[72px] h-[36px] font-[Satisfy] placeholder-black pl-8 outline-none mt-10' type="text" placeholder='Name:' onChange={(e)=>setName(e.target.value)} value={name}/>
                        <input className='block w-full bg-[#FFC3A1] md:text-[24px] rounded-[137px] md:h-[98px]   h-[36px] font-[Satisfy] placeholder-black pl-8 outline-none mt-2' type="text" placeholder='Email:' onChange={(e) => setEmail(e.target.value)} value={email} />
                        <textarea className='block w-full md:h-[258px] h-[128px] bg-[#FFC3A1] md:text-[24px] rounded-[46px] font-[Satisfy] placeholder-black pl-8 outline-none mt-2 pt-4' placeholder='Your massage ...' onChange={(e) => setMessage(e.target.value)} value={message} />
                        <div className='justify-end flex items-center'>
                            <h2 className={`font-[Satisfy] w-full text-left text-[24px] ${messagerFormInfo.toLowerCase() == "email sent" ? "text-green-500" : "text-red-900"}`}>{messagerFormInfo}</h2>
                            <button className='md:h-[72px] md:w-[118px] h-[36px] w-[64px] bg-[#FFC3A1] rounded-[136px] font-[Satisfy] text-[24px] mt-4' onClick={(e) => sendMessageToKimia(e)}>Send</button> 
                           
                        </div>
                   
                    </form>
                </section>   

            </main>
            <footer className='flex justify-center gap-8 items-center pt-10 pb-4'>
                {/* <i><a href='https://www.pexels.com/search/construction/' target="_blank"><img src={pexels} alt="instagram" /></a></i> */}
                <i><a href='https://www.linkedin.com/in/kimia-khorram-485151162/' target="_blank"><img src={linkedin}  alt="linkedin"/></a></i>
                <i><a href='https://twitter.com/kimia_khorram' target="_blank"><img src={twitter} alt="twitter" /></a></i>
            </footer>

        </div>
    )
}

export default Home