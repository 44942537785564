import React from 'react'

const VolunteerItem = ({src}) => {
  return (
      <div className='w-[360px] h-[360px] bg-[#FFC3A1] flex justify-center items-center m-auto'>
          <img src={ src } className="w-full h-full p-4" alt='volunteer jobs' /> 
      </div>
  )
}

export default VolunteerItem