import React from 'react'
import logo from '../images/Logo.png'
const Header = ({ isAbout, isDesign, isContact }) => {
    const textShadow = { textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }
    // console.log(isAbout, isDesign, isContact)
  return (
      <header className='pt-6 sticky top-0 z-50 bg-[#A75D5D]'>
          <img src={logo} className="h-[80px] hidden md:block" />
          <div className='flex justify-between font-[Puritan] items-center'>
              <h2 style={textShadow} className='md:text-[36px] text-[#593737] italic'>Kimia Arch & Design</h2>
              <nav className=''>
                  <ul className='font-[Puritan] flex gap-6 md:text-[24px]  '>
                      <li><a href='#about' className={`${isAbout ? "text-[#FFC3A1]" : "text-[#593737]"} `}>About</a></li>
                      <li><a href='#design' className={`${isDesign && !isAbout ? "text-[#FFC3A1]" : "text-[#593737]"} `}>Designs</a></li>
                      <li><a href='#contact' className={`${isContact && !isDesign ? "text-[#FFC3A1]" : "text-[#593737]"} `}>Contact</a></li>
                  </ul>
              </nav>
          </div>
      </header>
  )
}

export default Header